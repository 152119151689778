import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private matSnackBar = inject(MatSnackBar);

  public showMessage(message: string, duration = 3000): void {
    this.matSnackBar.open(message, 'Ok', { duration });
  }

  public showError(message: string, duration = 10000): void {
    this.matSnackBar.open(message, 'Ok', {
      duration,
      panelClass: ['error-snackbar']
    });
  }
}
